exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-craftmanship-tsx": () => import("./../../../src/pages/craftmanship.tsx" /* webpackChunkName: "component---src-pages-craftmanship-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-material-tsx": () => import("./../../../src/pages/material.tsx" /* webpackChunkName: "component---src-pages-material-tsx" */),
  "component---src-pages-portal-tsx": () => import("./../../../src/pages/portal.tsx" /* webpackChunkName: "component---src-pages-portal-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-services-[name]-tsx": () => import("./../../../src/pages/services/[name].tsx" /* webpackChunkName: "component---src-pages-services-[name]-tsx" */),
  "component---src-pages-services-strapi-service-slug-tsx": () => import("./../../../src/pages/services/{StrapiService.slug}.tsx" /* webpackChunkName: "component---src-pages-services-strapi-service-slug-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

